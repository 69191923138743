import React from 'react'

function NotFound() {
  return (
    <div>
      <p>sorry!!!</p>
      <img src="/images/404.jpg" alt='Not Found'/>

    </div>
  )
}

export default NotFound